
import { computed, onMounted, watch, defineComponent, ref } from "vue";
import { Form, useForm } from "vee-validate";

import { GoogleMap, Marker } from "vue3-google-map";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";

import ApiService from "@/core/services/ApiService";
import { AxiosRequestConfig } from "axios";
import { Actions } from "@/store/enums/StoreEnums";

interface TransportData {
  name: string;
  cuit: string;
}

type Location = {
  lat: number | null;
  lng: number | null;
};

export default defineComponent({
  name: "predonor-form",
  props: {
    title: {
      type: String,
      default: "Precarga de Donante",
    },
    showRequired: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Array,
      default: () => {
        return ["type"];
      },
    },
  },
  components: {
    GoogleMap,
    Marker,
    Form,
  },
  data() {
    return {
      endpoint: "preloads",
      mapStyle: [
        {
          featureType: "transit",
          stylers: [
            {
              color: "#808080",
            },
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "labels",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
      ],
      geolocateLoading: false,
      loading: false,
      localityList: [],
      stateList: [],
      criticalError: null,
      zoomLevel: 16,
      errors: {},
      entity: {
        id: "",
        type: "",
        workdayId: "",
        firstName: "",
        lastName: "",
        transportId: "",
        visitorId: "",
        scheduleId: 1,
        answeredQuestions: false,
        electronicSignature: "",
        crossStreet1: "",
        crossStreet2: "",
        addressReference: "",
        isGeoLocated: false,
        status: "",
        birthdate: "",
        fum: "",
        latitude: "",
        longitude: "",
        stateId: "",
        localityId: "",
        zipCode: "",
        dni: "",
        address: "",
      },
    };
  },
  setup() {
    const mapRef = ref(null);
    const markerRef = ref(null);
    const location = ref<Location | null>({ lat: -34.603722, lng: -58.381592 });
    const entityLoc = computed({
      get: () => location,
      set: (value) => {
        location.value = value.value;
      },
    });

    return { entityLoc };
  },

  methods: {
    updateCoordinates(event) {
      this.entityLoc.value = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      } as Location;
      this.entity.isGeoLocated = false;
    },

    useCurrentLocation() {
      this.geolocateLoading = true;

      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.entityLoc.value = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          } as Location;

          this.entity.isGeoLocated = true;

          this.geolocateLoading = false;
        },
        (error) => {
          // TODO: Error Callback logic
        }
      );

      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const geocoding = require("geocoding");
    },
    catchErrors(error) {
      if (error.response.status == 451) {
        Swal.fire({
          title: error.response.data.message,
          input: "password",
          showCancelButton: true,

          inputPlaceholder: "Ingrese su contraseña",
          html: '<textarea placeholder="Motivo/Razon" type="text" id="swal-textarea" class="swal2-textarea" />',
          icon: "question",
          buttonsStyling: false,
          cancelButtonText: "Cancelar",
          confirmButtonText: "Firmar",
          customClass: {
            cancelButton: "btn fw-bold btn-secondary",
            confirmButton: "btn fw-bold btn-primary",
          },
        }).then((response) => {
          this.entity.electronicSignature = response.data.signature;
          this.storeFormData();
        });
      } else {
        this.errors = error.response.data.errors;

        Swal.fire({
          text: error.response.data.message,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Cerrar",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      }
    },
    submitForm() {
      this.errors = {};
      if (
        this.entityLoc.value !== null &&
        this.entityLoc.value.lat !== null &&
        this.entityLoc.value.lng !== null
      ) {
        this.entity.latitude = this.entityLoc.value.lat.toString();
        this.entity.longitude = this.entityLoc.value.lng.toString();
      }

      this.storeFormData();
    },
    storeFormData() {
      if (this.entity.id) {
        return ApiService.update(
          this.endpoint,
          this.entity.id.toString(),
          this.entity
        )
          .then((response) => {
            this.$router.back();
          })
          .catch(this.catchErrors);
      } else {
        return ApiService.post(this.endpoint, this.entity)
          .then((response) => {
            this.$router.back();
          })
          .catch(this.catchErrors);
      }
    },
    selectedState() {
      this.entity.localityId = "";
      this.entity.zipCode = "";

      this.refreshLocalities();
    },
    selectedLocality() {
      // TODO: Something with locality
    },
    refreshLocalities() {
      ApiService.query("localities", {
        params: { stateId: this.entity.stateId },
      }).then((response) => {
        this.localityList = response.data;
      });
    },
  },
  mounted() {
    ApiService.query("index/states", {
      params: { permission: "place_visit" },
    }).then((response) => {
      this.stateList = response.data;
    });

    if (this.$route.params.workdayId) {
      this.entity.workdayId = this.$route.params.workdayId.toString();
    }

    if (this.$route.params.preloadId) {
      this.loading = true;
      ApiService.get(
        this.endpoint,
        this.$route.params.preloadId.toString()
      ).then((response) => {
        this.entity = response.data;
        this.refreshLocalities();
        this.loading = false;
      });
    }
  },
});
